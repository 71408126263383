.page-head {
	@include gradient();
	padding-bottom: 1rem;
	margin-bottom: 3rem;

	.breadcrumb {
		justify-content: center;
	}
}


