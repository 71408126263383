.report-header {
	padding-bottom: 10px;
	border-bottom: 1px solid;
	margin-bottom: 20px;
	table {
		
		 tr {
			 td {
				border-collapse: collapse;
				padding: 3pt 0;
				.logo {
					display: flex;
					align-items: center;
					img {
						width: 50px;
						flex-shrink: 0;
						height: auto;
					}

					.company {
						padding-left: 14px;
						h3 {
							font-family: $font-secondary;
							margin: 0;
							font-size: 18pt;
							font-weight: bold;
						}
						p {
							margin: 0;
							font-size: 9pt;
						}
					}
				}

				.header-right {
					text-align: right;
					tr {
						td {
							&:last-child {
								width: 30px;
							}

							i {
								font-size: 12pt;
							}

							font-size: 10pt;
						}
					}
				}
			}
		}
	}
}