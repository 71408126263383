*,html {
    font-size: 14px;
}
body {
	font-family: $font-primary;
	background-color: rgb(241, 245, 249);
    margin: 0;
    padding: 0;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}

.material-symbols-outlined {
    font-variation-settings: 'FILL'0,
        'wght'300,
        'GRAD'0,
        'opsz'48;

    &.w500 {
        font-variation-settings: 'FILL'0,
            'wght'500,
            'GRAD'0,
            'opsz'48;
    }
}
// @media screen and (max-width: 767px) {
// 	body {
// 		padding-top: 4rem;
// 	}
// }