.sidebar {
    width: 50%;
    background: black;
    
  .left-side{
    
    ul{
      list-style-type: none;
      .react-tooltip {
        z-index: 2;
    }
    }
   
  }
    .sidebar-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      text-decoration: none;
      color: #333;
      cursor: pointer;
      
  
      &:hover {
        background: #ddd;
      }
  
      i {
        margin-right: 10px;
      }
  
      .sidebar-label {
        flex-grow: 1;
      }
  
      .submenu {
        display: none;
        background: #f0f0f0;
        padding: 10px;
  
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  
    .toggle-input {
      display: none;
    }
  
    // .toggle-input:checked + .sidebar-item .submenu {
    //   display: block;
    //   position: absolute;
    //   top: 0;
    //   left: 100%;
    //   background-color: black;
    // }
  }
  