.side-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding-top: 35px;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      // padding: 15px 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: $primary;
      &.active,
      &.fouse,
      &.hover {
        i {
          @include bgPrimary(0.05);
          border-radius: 6px;
        }
      }

      i {
        display: block;
        padding: 2px 12px;

        &:hover,
        &.active {
          @include bgPrimary(0.05);
          color: $primary;
          border-radius: 6px;
          text-align: center;
        }
      }

      span {
        text-align: center;
        font-size: 0.6rem;
        font-weight: 500;
        margin-top: 2px;
      }
    }
  }



  i {
    margin-right: 0px;
  }

  .sidebar-label {
    flex-grow: 1;
  }

  .submenu {
    display: none;
    background: #f0f0f0;
    padding: 10px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
}

.toggle-input {
  display: none;
}


// .toggle-input:checked + .sidebar-item .submenu {
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 100%;
//   background-color: black;
// }