.table-summary {
	 
	.col1 {
		width: 60%;
		vertical-align: top;
	}
	.col2 {
		padding-left: 10pt;
		width: 40%;
		vertical-align: top;
	}

	.report-table {

		tr {
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}

		td {
			padding: 3pt 6pt;
			font-size: 9pt;
		}
	}
	
}

.report-stock-wrap {
	display: flex;
	flex-wrap: wrap;

	.report-stock-item {
		h2 {
			font-size: 10pt;
			font-weight: bold;
			margin: 0 0 2pt 0;
			text-transform: uppercase;

		}
		padding: 6px 0;
		width: 50%;
		border-bottom: 1px solid;
		font-size: 10pt;

		&:nth-child(even) {
			border-left: 1px solid;
			padding-left: 20px;
		}
		&:last-child {
			border-bottom: none;
		}
		&:nth-last-child(2) {
			border-bottom: none;
		}
	}
}

.report-date-wise-summary {
	th,td {
		font-size: 8pt !important;
		padding: 5pt !important;
	}
}