footer {
	background-color: $lightGray;
	position: relative;
	padding-top: 3rem;
	.footer-widget {
		h4 {
			font-weight: 600;
			color: $themeBlack;
			font-size: 1rem;
			margin-bottom: 1rem;
		}
		p {
			@include textBlack(0.5);
			font-size: .8rem;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 0.5rem;
				a {
					@include textBlack(0.7);
					font-size: .9rem;
					&:hover {
						color: $primary;
					}
				}
			}

		}
	}

	.copyright {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		padding: 1rem 0;
		margin-top: 1rem;
		.row {
			align-items: center;
		}
		.footer-social {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			li {
				margin-left: 1rem;
				font-size: 1.5rem;
				a {
					@include textBlack(0.5);
				}
			}
		}
	}
}


.cta-wrap {
	position: relative;
	margin-top: 4rem;
}
.call-to-action {
	background: linear-gradient(92.22deg, #751C1C 2.32%, #A6201D 52.53%, #D7241D 98.14%);
	border-radius: $radius;
	padding: 3.75rem;

	h5 {
		color: #FFF;
		font-size: 1.2rem;
		font-weight: 300;
	}
	h1 {
		@include textStroke($themeLight);
		font-weight: bold;
	}
	p{
		color: #FFF;
		font-size: 1.10rem;
	}

	.cta-button {
		text-align: right;
	}
}

.footer-social {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	li {
		a {
			display: block;
			margin-left: 1rem;
			i {
				font-size: 2rem;
			}
		}
	}
}


@media screen and (max-width: 767px) { 
	.call-to-action {
		padding: 2.75rem;
		text-align: center;
		.cta-button {
			text-align: center;
		}
	}

}