.icon-box-light {
    padding: 1.2rem .5rem;
    border-radius: $radius;
    background-color: #FFF;
    display: flex;
    align-items: center;
    &.border {
        border: 1px solid rgba(0,0,0,0.3);
    }
    .icon {
        width: 70px;
        span {
        	display: flex;
        	align-items: center;
        	justify-content: center;
        	width: 50px;
        	height: 50px;
        	font-size: 2rem;
        	border-radius: $radius;
        	background-color: $themeLight;
        	color: #FFF;
        	margin: auto;
        }
    }

    .content {
        
        padding-left: 10px;
        p {
            margin: 0;
            font-size: .9rem;
            @include textBlack(0.5);
        }

        h2 {
            margin: 0;
            font-size: 1.5rem;
        }
    }

    &.bg-yellow {
        .icon {
            span {
                @include bgWarning(0.2);
                color: $warning;
            }
        }
    }

    &.bg-theme {
        .icon {
            span {
                @include bgPrimary(0.2);
                color: $primary;
            }
        }
    }


    &.bg-second {
        .icon {
            span {
                @include bgSecondary(0.2);
                color: $secondary;
            }
        }
    }

    &.bg-green {
        .icon {
            span {
                @include bgGreen(0.2);
                color: $green;
            }
        }
    }
    &.bg-red {
        .icon {
            span {
                @include bgRed(0.2);
                color: $red;
            }
        }
    }
}

.about-us{
    background-color: rgba(0,0,0,0.06);
   
}
.fact-box{
    text-align: center;
    
    span{
            width: 70px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include bgSecondary(0.2);
			color: $secondary;
			font-size: 1.8rem;
			border-radius: 50%;
            margin: auto;

    }

    h2 {
        margin-top: 1rem;
    }
}