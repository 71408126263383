.wrapper {

    width: 100%;
    background-color: rgb(241, 245, 249);
    height: 100vh;
    overflow: hidden;






    .page-content {
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100vh - 90px);
        padding: 0 28px;
    }


    .daybook-content {
        height: calc(100vh - 90px);
        padding: 0 28px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    header {
        padding: 10px 40px 10px 30px;

        .header-items {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
            align-items: center;
            margin: 0;
            padding: 0;

            b {
                font-weight: 500;
                color: $primary;
            }

            span {
                color: rgb(148, 163, 184);
            }

            img {
                width: 40px;
            }
        }
    }

    .sub-menu-wrap {
        width: 190px;
        border-right: 1px solid #E2E8F0;
        background-color: #FFF;
        height: 100vh;
        padding: 20px;


        &.active {
            display: none;
        }

        h5 {
            margin: 0;
            font-size: .9rem;
        }

        .sub-menu {
            list-style-type: none;
            margin: 10px 0 0 0;
            padding: 0;

            li {
                padding-left: 15px;
                position: relative;
                margin-bottom: 0.5rem;

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 4px 0 4px 6px;
                    border-color: transparent transparent transparent rgb(148, 163, 184);
                    position: absolute;
                    top: 6px;
                    left: 0;
                }

                a {
                    font-size: 0.929rem;
                    color: rgb(148, 163, 184);

                    &:hover,
                    &.active {
                        color: $primary;
                    }
                }
            }
        }

    }


    .content-header {
        display: flex;
        justify-content: space-between;
        background: $themeLight;
        box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
        align-items: center;
        padding: .8rem 2rem;
        border-bottom-left-radius: 20px;

        .left {}

        .right {
            display: flex;
            justify-content: flex-end;

            .button {
                margin-left: .5rem;
            }
        }

        h1 {
            font-size: 1.5rem;
            margin: 0;
            color: $primary;
            font-weight: bold;
        }
    }

    >.content {

        padding: 2rem;
        position: relative;


    }

}

.daybook-dates-wrap {
    display: flex;
    align-items: center;

    h4 {
        margin-right: 16px;
    }
}

.page-list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        a {
            display: inline-block;
            padding: 2px 4px;
            background-color: $primary;
            color: #FFF;
            margin-right: 5px;
            border-radius: 3px;
            font-size: 14px;
            line-height: 1.3;

            &.current {
                background-color: $secondary;
            }
        }
    }
}




@media screen and (max-width: 767px) {
    .wrapper {
        display: block;

        .left-side {
            transform: translateX(-100%);
            transition: all 0.5s ease;

            &.active {
                transform: translateX(0);

            }
        }

        .right-side {
            padding-left: 0;

            >.content {
                padding: 1rem;
            }

            .content-header {
                flex-direction: column;
                align-items: flex-start;
                padding: 0.5rem 1rem;

                h1 {
                    font-weight: 600;
                    font-size: 1.2rem;
                    margin-bottom: .3rem;

                }


            }
        }
    }
}