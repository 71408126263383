.c-icon-box {
	display: flex;
	.icon {
		flex-basis: 20%;
		padding-right: 1rem;
	}

}

.contact-box{
	display: flex;
	background-color: rgba(0,0,0,0.03);
	padding: 1.5rem;
	border-radius: $radius;
	min-height: 150px;
	
	.icon{
		padding-right: 1rem;
		span {
			width: 70px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $primary;
			color: #FFF;
			font-size: 2.4rem;
			border-radius: 50%;
		}
	}

}
.map{
	iframe{
	border-radius: 0.5rem ;
	}
}