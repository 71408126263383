.pump-reading-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    &:hover {
        background-color: $themeLight;

        .pump-name {
            .number {
                background-color: $primary;
                color: #FFF;
            }
        }
    }

    .pump-name {
        display: flex;
        align-items: center;
        flex-basis: 15%;

        .number {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 1.2rem;
            @include bgPrimary(0.2);
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.6rem;
            font-weight: bold;
            flex-shrink: 0;
        }

        &.petrol {
            .number {
                background-color: $color-petrol;
                color: #FFF;
            }
        }

        &.diesel {
            .number {
                background-color: $color-diesel;
                color: #FFF;
            }
        }
    }

    .readings {
        margin: 0 1rem;
        display: flex;
        flex-basis: 65%;

        >div {
            width: 33%;
        }

        .total {
            p {
                margin: 0;
            }

            h4 {
                margin: 0;
            }
        }

        label {
            font-size: 0.8rem;
            padding-right: 0;
            white-space: nowrap;
        }

        .last-reading {
            .form-floating {
                .form-control {
                    margin-left: -1px;
                    border-radius: $radius 0 0 $radius;

                    &:focus {
                        position: relative;
                        z-index: 1;

                        &~label {
                            z-index: 1;
                        }
                    }
                }


            }
        }

        .current-reading {
            margin-left: -2px;

            .form-floating {
                .form-control {
                    border-radius: 0;

                }
            }
        }

        .returned {
            margin-left: -1px;

            .form-floating {
                .form-control {
                    border-radius: 0 $radius $radius 0;
                }
            }
        }
    }

    .qty {
        flex-basis: 20%;

        p {
            margin: 0;
            @include textBlack(0.5);
        }

        h4 {
            margin: 0;
            font-size: 1.3rem;
        }
    }
}

.card-sales-list {
    min-height: 481px;
}

.table-sales-list {
    font-size: .9rem;
}

.products-total {
    display: flex;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: $radius;

    .pt-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px;
        flex: 1;
        background-color: #FFF;
        border-radius: $radius;

        .value {
            font-weight: bold;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.pump-icon {
    position: relative;

    p {
        margin: 0;
        position: absolute;
    }

    img {
        max-width: 60px;
    }
}


.sale-wrap {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid rgb(226, 232, 240);

    .sale-head {
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-size: 16px;
            margin: 0;

            span {
                font-size: 16px;
                display: inline-block;
                margin-right: 6px;
                color: rgba(0, 0, 0, .5);
            }
        }

    }

    .sale-footer {
        padding: 20px 40px;
        border-top: 1px solid rgb(226, 232, 240);

    }

    .sale-item {
        padding: 20px 40px;
        border-top: 1px solid rgb(226, 232, 240);

        &.bg-light1 {
            background-color: #F8FAFC;
        }

        h5 {
            font-size: 1.3rem;
            font-weight: 500;
            margin: 0;

            span {
                color: rgba(0, 0, 0, .5);
                font-size: 1rem;
            }
        }
    }
}


@media screen and (max-width: 767px) {
    .pump-reading-item {
        flex-direction: column;
        margin-bottom: 1rem;

        .pump-name {
            margin-bottom: 1rem;
        }

        .readings {
            margin: 0;
        }
    }
}