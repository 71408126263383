.counter_bg {
	//background-image: url("../images/landing/counter_bg.jpg");
	background-attachment: fixed;
	background-size: cover;
	min-height: 400px;
	display: flex;
	align-items: center;

	.count-box {
		h3 {
			color: $secondary;
			font-weight: 600;
			font-size: 2rem;
		}
		p {
			color: rgba(255, 255, 255, .8);
		}
	}
}

.hero {
	position: relative;
	padding-top: 6rem;
	display: flex;
	align-items: center;
	.hero-content {
		h1 {
			font-weight: bold;
			margin-bottom: 2rem;
		}

	}
}

.pump-logos {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	li {
		img {
			max-width: 170px;
			display: inline-block;
			margin: 10px;
		}
	}
}

.feature-section {

	img {
		max-width: 500px;
	}
	h2 {
		font-weight: bold;
		font-size: 1.8rem;
		margin-bottom: 1.5rem;
	}

	p {
		@include textBlack(0.6);
	}
}

.reports {

	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	li {
		background-color:#FFF;
		border-radius: $radius;
		padding: 2rem;
		text-align: center;
		flex-basis: 17%;
    	margin: 1%;
    	justify-content: center;
    	box-shadow: 0px 11px 32px #0080f914;

		img {
			width: 30px;
			opacity: .5;
		}

		i {
			font-size: 3rem;
		}

		p {
			font-weight: bolder;
			font-size: 1.4rem;
			max-width: 110px;
    		margin: 0 auto 1.5rem auto;
    		line-height: 1.8rem;
		}

		.button-sm {
			padding: .5rem;
			font-size: .8rem;
		}
	}
}

.features_2 {
	padding: 3rem 0;
	.feature_box_2 {
		background-color: #FFF;
		box-shadow: 0px 11px 32px #0080f914;
		border-radius: 25px;
		padding: 2rem;
		text-align: center;

		.icon {
			margin-bottom: 1rem;
			span {
				width: 60px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin: auto;
				@include bgSecondary(0.2);
				color: $secondary;
				font-size: 2rem;
			}
		}

		h3 {
			font-size: 1.2rem;
		}
		p {
			@include textBlack(0.6);
			font-size: .9rem;
		}
	}
}

.cta {
	background-color: $primary;
	padding: 5rem 0;

	.cta-content {
		text-align: center;
		color: #FFF;

		h3 {
			font-size: 2.5rem;
			margin-bottom: 1.4rem;
		}

		p {
			font-size: 1.2rem;
			font-weight: 300;
		}

		.cta-buttons {
			margin-top: 2rem;
			.button {
				margin: .5rem;
			}
		}
	}
}



