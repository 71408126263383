header {
    margin-bottom: .8rem;
    background-color: #FFF;
    .page-title {
        display: flex;
        align-items: center;
        // span {
        //     display: none;
        // }
        h3 {
            margin: 0;
            font-size: 1.4rem;
        }
    }

    .ant-row {
        align-items: center;
    }

    .header-items {
        .header-img {
            display: flex;
            align-items: center;
            span {
                margin-right: .6rem;
            }
        }
    }
}

.header-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
            color: #000;
            span {
                font-size: .9rem;
                margin-top: 2px;
            }
        }
    }
}