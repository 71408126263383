.simple-section {
    padding: 1rem 0;
    position: relative;
}

.section-title {
    font-size: 2.27rem;
    font-weight: 600;
    color: $themeBlack;
    text-align: center;
    position: relative;
    margin-bottom: 3rem;
    font-family: $font-secondary;

    &::after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: $primary;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
        margin: auto;
        border-radius: 1rem;
    }

    &.title-sm {
        font-size: 1.2rem;
        margin-bottom: 2rem;
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     width: 30px;
    //     height: 38px;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: -35px;
    //     margin: auto;
    //     animation: move4 5s infinite linear;
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    // }

    &.title-left {
        text-align: left;

        &::after {
            margin-left: 0;
        }
    }

    &.title-white {
        color: #FFF;

        &::after {
            background-color: #FFF;
        }


    }
}


.text-red {
    color: $red;
}

.text-green {
    color: $green;
}

.text-center {
    text-align: center;
}

.text-end,
.text-right {
    text-align: right;
}

.ant-statistic.statistic-font-large .ant-statistic-content-value-int {
    font-size: 2.5rem;
}

.blink {
    animation: blinker 1s infinite;
}

.d-inline-flex {
    display: inline-flex;
}

@keyframes blinker {
    from {
        opacity: 1.0;
    }

    50% {
        opacity: 0.5;
    }

    to {
        opacity: 1.0;
    }
}


.profile-wrap .card-title {
    background-color: $lightGray;
    padding: 1rem;
    margin-bottom: 1rem;
}

.dashboard-title {
    font-size: 1.8rem;
    font-family: $font-secondary;
    font-weight: 500;
    color: $themeBlack;
    position: relative;
    margin-bottom: 2rem;

    &::after {
        content: "";
        display: block;
        width: 80px;
        height: 3px;
        background-color: rgba(0, 0, 0, .2);
        position: absolute;
        left: 0;
        bottom: -12px;
        border-radius: 1rem;
    }

    // &::before {
    //     content: "";
    //     display: block;
    //     width: 30px;
    //     height: 38px;
    //     background-image: url("../images/stonex-icon-white.svg");
    //     background-size: 100%;
    //     background-repeat: no-repeat;
    //     position: absolute;
    //     left: 0;
    //     bottom: -35px;
    //     animation: dashboardtitle 5s infinite linear;
    // }
}

.section-sub-title {
    font-size: 1.175rem;
    text-align: center;
    margin: 0;
    font-weight: 300;
    @include textBlack(0.5);

    &.title-left {
        text-align: left;
    }
}



.section-shapes {
    .shape {
        position: absolute;

        &.shape-1 {
            left: 2%;
        }

        &.shape-2 {
            right: 2%;
        }

        &.shape-3 {
            right: 0;
            bottom: 0;
        }
    }
}

.list-bullets {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-type: none;

    li {
        @include textBlack(0.5);
        font-size: 1.125rem;
        position: relative;
        padding-left: 1.4rem;

        &::before {
            font-family: remixicon !important;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            content: "\EB7C";
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
            font-size: .7rem;
            top: .4rem;
        }
    }

    &.link-white {
        li {
            color: #FFF;

            &::before {
                color: #FFF;
            }
        }
    }
}

.text-right {
    text-align: right;
}


@media screen and (max-width: 767px) {
    .section-title {
        font-size: 2rem;
    }

    .dash-head {
        .breadcrumb {
            justify-content: center;
            border-bottom: 1px solid rgba(255, 255, 255, .3);
            padding-bottom: 1rem;
        }
    }

    .body-wrap {
        padding-top: 5rem;
    }
}



.alert {
    border-radius: $radius;
}



.icon-box {
    display: flex;
    align-items: center;

    .icon {
        padding-right: 10px;

        span {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            @include bgPrimary(0.13);
            color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                line-height: 0;
            }
        }
    }

    .info {
        p {
            margin: 0;

        }

        h4 {
            margin: 0;
            font-size: 14px;
        }
    }
}
.status-box {
    display: inline-flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border: 0.6px solid rgb(203, 213, 225);
    border-radius: 6px;
    &.success {
        border-color: $green;
        i {
            color: $green;
        }
    }
    &.danger {
        border-color: $red;
        i {
            color: $red;
        }
    }
    i{
        font-size: 15px;
    }
}
.spin-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 270px;

  
   
    
  
}
.ant-breadcrumb {
    margin-bottom: 1rem !important;
}