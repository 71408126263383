.v-account-info {
	border: 1px solid #ced4da;
	border-radius: .25rem;
	padding: .42rem 0.75rem;
	display: flex;
	justify-content: space-between;
	.item {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		font-size: .9rem;
		.label {
			@include textBlack(0.5);
			font-weight: 300;
		}
		.value {
			font-weight: 600;
		}
	}
}