@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap");
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.simple-section {
  padding: 1rem 0;
  position: relative;
}

.section-title {
  font-size: 2.27rem;
  font-weight: 600;
  color: #121606;
  text-align: center;
  position: relative;
  margin-bottom: 3rem;
  font-family: "Outfit", sans-serif;
}
.section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background-color: #1677ff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  margin: auto;
  border-radius: 1rem;
}
.section-title.title-sm {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.section-title.title-left {
  text-align: left;
}
.section-title.title-left::after {
  margin-left: 0;
}
.section-title.title-white {
  color: #FFF;
}
.section-title.title-white::after {
  background-color: #FFF;
}

.text-red {
  color: #FF7761;
}

.text-green {
  color: #00B5BD;
}

.text-center {
  text-align: center;
}

.text-end,
.text-right {
  text-align: right;
}

.ant-statistic.statistic-font-large .ant-statistic-content-value-int {
  font-size: 2.5rem;
}

.blink {
  animation: blinker 1s infinite;
}

.d-inline-flex {
  display: inline-flex;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.profile-wrap .card-title {
  background-color: #F8F8F8;
  padding: 1rem;
  margin-bottom: 1rem;
}

.dashboard-title {
  font-size: 1.8rem;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  color: #121606;
  position: relative;
  margin-bottom: 2rem;
}
.dashboard-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  bottom: -12px;
  border-radius: 1rem;
}

.section-sub-title {
  font-size: 1.175rem;
  text-align: center;
  margin: 0;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
.section-sub-title.title-left {
  text-align: left;
}

.section-shapes .shape {
  position: absolute;
}
.section-shapes .shape.shape-1 {
  left: 2%;
}
.section-shapes .shape.shape-2 {
  right: 2%;
}
.section-shapes .shape.shape-3 {
  right: 0;
  bottom: 0;
}

.list-bullets {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
}
.list-bullets li {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.125rem;
  position: relative;
  padding-left: 1.4rem;
}
.list-bullets li::before {
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  color: #1677ff;
  font-size: 0.7rem;
  top: 0.4rem;
}
.list-bullets.link-white li {
  color: #FFF;
}
.list-bullets.link-white li::before {
  color: #FFF;
}

.text-right {
  text-align: right;
}

@media screen and (max-width: 767px) {
  .section-title {
    font-size: 2rem;
  }

  .dash-head .breadcrumb {
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 1rem;
  }

  .body-wrap {
    padding-top: 5rem;
  }
}
.alert {
  border-radius: 12px;
}

.icon-box {
  display: flex;
  align-items: center;
}
.icon-box .icon {
  padding-right: 10px;
}
.icon-box .icon span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(6, 25, 86, 0.13);
  color: #1677ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-box .icon span i {
  line-height: 0;
}
.icon-box .info p {
  margin: 0;
}
.icon-box .info h4 {
  margin: 0;
  font-size: 14px;
}

.status-box {
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border: 0.6px solid #cbd5e1;
  border-radius: 6px;
}
.status-box.success {
  border-color: #00B5BD;
}
.status-box.success i {
  color: #00B5BD;
}
.status-box.danger {
  border-color: #FF7761;
}
.status-box.danger i {
  color: #FF7761;
}
.status-box i {
  font-size: 15px;
}

.spin-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 270px;
}

.ant-breadcrumb {
  margin-bottom: 1rem !important;
}

*, html {
  font-size: 14px;
}

body {
  font-family: "Outfit", sans-serif;
  background-color: #f1f5f9;
  margin: 0;
  padding: 0;
}

a {
  color: #1677ff;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #F28D20;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
}
.material-symbols-outlined.w500 {
  font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
}

.button {
  display: inline-block;
  vertical-align: middle;
  padding: 1rem 1.3rem;
  border: none;
  text-align: center;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 12px;
  font-weight: 600;
}
.button.hover-on-dark:hover {
  color: #FFF;
  border-color: #FFF;
}
.button.button-v-small {
  padding: 0.5rem 1.3rem;
}

.button-long {
  min-width: 130px;
}

.button-dark {
  background-color: #191824;
  color: #FFF;
}
.button-dark:hover {
  background-color: transparent;
  border-color: #191824;
  color: #191824;
}

.button-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 0.6rem 0.6rem 1.3rem;
  white-space: nowrap;
}
.button-icon span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.button-icon.icon-right span {
  margin-left: 0.8rem;
}
.button-icon.icon-left {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0.6rem 1rem 0.6rem 1rem;
}
.button-icon.icon-left span, .button-icon.icon-left i {
  margin-left: 0;
  margin-right: 0.4rem;
}
.button-icon.button-dark span {
  background-color: #fbfbfb;
  color: #191824;
}

.button-white {
  background-color: #FFF;
  box-shadow: 0 3px 20px #0000000b;
  color: #000;
}

.button-light {
  background-color: #E8E8E8;
  color: #65676B;
}
.button-light:hover {
  background-color: transparent;
  border-color: #65676B;
  color: #65676B;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.button-primary {
  background-color: #1677ff;
  color: #FFF;
  font-weight: bold;
  border: 1px solid transparent;
}
.button-primary:hover {
  background-color: transparent;
  border-color: #1677ff;
  color: #1677ff;
  opacity: 1;
}

.button-secondary {
  background-color: #F28D20;
  color: #FFF;
  font-weight: bold;
}
.button-secondary:hover {
  background-color: transparent;
  border-color: #F28D20;
  color: #F28D20;
  opacity: 1;
}

.button-outline-secondary {
  border-color: #F28D20;
  color: #F28D20;
}
.button-outline-secondary:hover {
  color: #FFF;
  background-color: #F28D20;
}

.button-outline-primary {
  border-color: #1677ff;
  color: #1677ff;
  background-color: transparent;
}
.button-outline-primary:hover {
  color: #FFF;
  background-color: #1677ff;
}

.button-fb {
  background-color: #3C5A9A;
  color: #FFF;
  font-weight: bold;
}
.button-fb:hover {
  background-color: transparent;
  border-color: #3C5A9A;
  color: #3C5A9A;
  opacity: 1;
}

.button-success {
  background-color: #00B5BD;
  color: #FFF;
}
.button-success:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-danger {
  background-color: #FF7761;
  color: #FFF;
}
.button-danger:hover {
  color: #FFF;
  opacity: 0.8;
}

.button-round {
  border-radius: 12px;
}

.button-block {
  width: 100%;
  display: block;
}

.button-outline-gray {
  background-color: #F5F5F5;
  border: 1px solid #707070;
  color: #292724;
  font-weight: bold;
}
.button-outline-gray:hover {
  color: #292724;
  background-color: #FFF;
}

.button-large-icon {
  display: flex;
  flex-direction: column;
  line-height: 3rem;
}
.button-large-icon .icon {
  font-size: 3rem;
}
.button-large-icon .label {
  font-size: 1.5rem;
}

.input-icon {
  position: relative;
}
.input-icon .form-control,
.input-icon .form-select {
  padding-left: 3rem !important;
}
.input-icon span {
  position: absolute;
  left: 10px;
  top: 38px;
  border-radius: 50%;
  color: rgba(59, 130, 246, 0.5);
  font-size: 1.6rem;
}

.form-control:focus,
.form-select:focus {
  outline: 1px solid #1677ff;
  box-shadow: none;
}
.form-control:focus ~ span,
.form-select:focus ~ span {
  color: #1677ff;
}

.form-group label {
  margin-bottom: 0.3rem;
  color: rgba(0, 0, 0, 0.6);
}
.form-group .form-control, .form-group .form-select {
  border-radius: 12px;
  min-height: 48px;
}
.form-group .form-control:placeholder, .form-group .form-select:placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.input-vertical > .ant-row {
  display: block;
}

.radio-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.radio-logos li {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2rem 0 0 0;
  border-radius: 12px;
  position: relative;
  background-color: #FFF;
  flex-basis: 14%;
  margin: 1%;
  opacity: none;
}
.radio-logos li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.radio-logos li input {
  opacity: 0;
  position: absolute;
  left: 0;
}
.radio-logos li input + label {
  display: block;
  cursor: pointer;
  text-align: center;
}
.radio-logos li input + label::before {
  font-family: remixicon !important;
  font-weight: 400;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  font-size: 1.3rem;
}
.radio-logos li input + label img {
  max-width: 40px;
  margin-bottom: 0.5rem;
}
.radio-logos li input:checked + label::before {
  content: "";
  color: #1677ff;
}
.radio-logos li input:checked + label .count {
  background-color: #F28D20;
  color: #FFF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.card {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
}
.card .card-title-dash {
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}
.card .card-body {
  padding: 40px;
}
.card.card-welcome {
  padding: 0.5rem 1.5rem;
}
.card.card-welcome p {
  margin: 0;
}
.card.card-welcome h4 {
  font-family: "Outfit", sans-serif;
  font-size: 2rem;
}
.card.card-welcome h4 span {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
}
.card.card-welcome .logged-in {
  color: rgba(0, 0, 0, 0.4);
}
.card.card-welcome .logged-in span {
  color: #1677ff;
  font-weight: 500;
}

.card-title-icon {
  display: flex;
  align-items: center;
}
.card-title-icon .icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: rgba(6, 25, 86, 0.05);
  color: #1677ff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-title-icon .icon span {
  font-size: 28px;
  line-height: unset;
}
.card-title-icon .info {
  padding-left: 1rem;
}
.card-title-icon .info h4 {
  margin: 0;
  color: #374151;
}
.card-title-icon .info p {
  color: #94a3b8;
  margin: 0;
}

.card-title {
  margin-bottom: 0.9rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}
.card-title .card-action {
  position: absolute;
  right: 0;
  top: 0;
}
.card-title .card-action .button {
  padding: 0.1rem 0.8rem;
  font-size: 0.8rem;
}

.equal-cards .card {
  min-height: 120px;
}

.card-title-2 {
  position: relative;
  font-family: "Outfit", sans-serif;
  font-size: 1.2rem;
  display: flex;
  padding-left: 2rem;
}
.card-title-2 i {
  margin-right: 0.4em;
  color: #F28D20;
  position: absolute;
  left: 0;
  top: -3px;
  font-size: 1.5rem;
}

.icon-box-light {
  padding: 1.2rem 0.5rem;
  border-radius: 12px;
  background-color: #FFF;
  display: flex;
  align-items: center;
}
.icon-box-light.border {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.icon-box-light .icon {
  width: 70px;
}
.icon-box-light .icon span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  border-radius: 12px;
  background-color: #fbfbfb;
  color: #FFF;
  margin: auto;
}
.icon-box-light .content {
  padding-left: 10px;
}
.icon-box-light .content p {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
}
.icon-box-light .content h2 {
  margin: 0;
  font-size: 1.5rem;
}
.icon-box-light.bg-yellow .icon span {
  background-color: rgba(244, 183, 30, 0.2);
  color: #F4B71E;
}
.icon-box-light.bg-theme .icon span {
  background-color: rgba(6, 25, 86, 0.2);
  color: #1677ff;
}
.icon-box-light.bg-second .icon span {
  background-color: rgba(242, 141, 32, 0.2);
  color: #F28D20;
}
.icon-box-light.bg-green .icon span {
  background-color: rgba(0, 181, 189, 0.2);
  color: #00B5BD;
}
.icon-box-light.bg-red .icon span {
  background-color: rgba(255, 119, 97, 0.2);
  color: #FF7761;
}

.about-us {
  background-color: rgba(0, 0, 0, 0.06);
}

.fact-box {
  text-align: center;
}
.fact-box span {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(242, 141, 32, 0.2);
  color: #F28D20;
  font-size: 1.8rem;
  border-radius: 50%;
  margin: auto;
}
.fact-box h2 {
  margin-top: 1rem;
}

.user {
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  background-color: #f1f5f9;
}
.user .photo img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}
.user .info {
  padding-left: 0.5rem;
}
.user .info h3 {
  margin: 0.5rem 0 0 0;
  text-transform: capitalize;
  font-size: 1rem;
}
.user .info h3 .badge {
  padding: 3px 10px;
  font-size: 1rem;
}
.user .info p {
  margin: 0;
  font-size: 0.7rem;
}
.user .info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.user .info ul li {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user .info ul li a {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
}
.user .info ul li a i {
  margin-right: 4px;
  margin-top: 1px;
  font-size: 1rem;
}
.user .info ul li a.verified i {
  color: #00B5BD;
}
.user .info ul li a.not-verified i {
  color: #FF7761;
}

.side-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.side-bar li {
  padding-top: 35px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.side-bar li:last-child {
  margin-bottom: 0;
}
.side-bar li a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #1677ff;
}
.side-bar li a.active i, .side-bar li a.fouse i, .side-bar li a.hover i {
  background-color: rgba(6, 25, 86, 0.05);
  border-radius: 6px;
}
.side-bar li a i {
  display: block;
  padding: 2px 12px;
}
.side-bar li a i:hover, .side-bar li a i.active {
  background-color: rgba(6, 25, 86, 0.05);
  color: #1677ff;
  border-radius: 6px;
  text-align: center;
}
.side-bar li a span {
  text-align: center;
  font-size: 0.6rem;
  font-weight: 500;
  margin-top: 2px;
}
.side-bar i {
  margin-right: 0px;
}
.side-bar .sidebar-label {
  flex-grow: 1;
}
.side-bar .submenu {
  display: none;
  background: #f0f0f0;
  padding: 10px;
}
.side-bar .submenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.toggle-input {
  display: none;
}

.widget {
  background-color: #FFF;
  border: 1px solid #E0DFDC;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}
.widget .widget-title {
  font-size: 16px;
  font-weight: bold;
  color: #121606;
  position: relative;
}
.widget .widget-title .action {
  position: absolute;
  right: 0;
  top: 0;
}
.widget .widget-body {
  padding-top: 20px;
}

.list-info-body {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.list-info-body .list-info-value {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-info-body .info-box {
  display: flex;
  align-items: center;
}
.list-info-body .info-box .list-acc-info {
  padding: 0px 10px;
  line-height: 1.2rem;
}
.list-info-body .info-box .list-acc-info p {
  font-weight: 500;
  margin: 0;
}
.list-info-body .info-box .list-acc-info span {
  font-size: 0.9rem;
  color: #94a3b8;
}
.list-info-body .info-box .circle {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: white;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1677ff;
}
.list-info-body .info-box .circle span, .list-info-body .info-box .circle i {
  font-size: 1.3rem;
}

.user-box .box-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-right: 0.4px solid #cfc9c9;
}
.user-box .box-info.border-none {
  border-right: none;
}
.user-box .box-info i {
  font-size: 2.2rem;
}
.user-box .box-info b {
  font-weight: 600;
  font-size: 1.25rem;
}

.ant-breadcrumb {
  margin-bottom: 1rem;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -98px;
}

.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

.ham2 .top {
  stroke-dasharray: 40 121;
}

.ham2 .bottom {
  stroke-dasharray: 40 121;
}

.ham2.active .top {
  stroke-dashoffset: -102px;
}

.ham2.active .bottom {
  stroke-dashoffset: -102px;
}

.ham3 .top {
  stroke-dasharray: 40 130;
}

.ham3 .middle {
  stroke-dasharray: 40 140;
}

.ham3 .bottom {
  stroke-dasharray: 40 205;
}

.ham3.active .top {
  stroke-dasharray: 75 130;
  stroke-dashoffset: -63px;
}

.ham3.active .middle {
  stroke-dashoffset: -102px;
}

.ham3.active .bottom {
  stroke-dasharray: 110 205;
  stroke-dashoffset: -86px;
}

.ham4 .top {
  stroke-dasharray: 40 121;
}

.ham4 .bottom {
  stroke-dasharray: 40 121;
}

.ham4.active .top {
  stroke-dashoffset: -68px;
}

.ham4.active .bottom {
  stroke-dashoffset: -68px;
}

.ham5 .top {
  stroke-dasharray: 40 82;
}

.ham5 .bottom {
  stroke-dasharray: 40 82;
}

.ham5.active .top {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}

.ham5.active .bottom {
  stroke-dasharray: 14 82;
  stroke-dashoffset: -72px;
}

.ham6 .top {
  stroke-dasharray: 40 172;
}

.ham6 .middle {
  stroke-dasharray: 40 111;
}

.ham6 .bottom {
  stroke-dasharray: 40 172;
}

.ham6.active .top {
  stroke-dashoffset: -132px;
}

.ham6.active .middle {
  stroke-dashoffset: -71px;
}

.ham6.active .bottom {
  stroke-dashoffset: -132px;
}

.ham7 .top {
  stroke-dasharray: 40 82;
}

.ham7 .middle {
  stroke-dasharray: 40 111;
}

.ham7 .bottom {
  stroke-dasharray: 40 161;
}

.ham7.active .top {
  stroke-dasharray: 17 82;
  stroke-dashoffset: -62px;
}

.ham7.active .middle {
  stroke-dashoffset: 23px;
}

.ham7.active .bottom {
  stroke-dashoffset: -83px;
}

.ham8 .top {
  stroke-dasharray: 40 160;
}

.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}

.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}

.ham8.active .top {
  stroke-dashoffset: -64px;
}

.ham8.active .middle {
  transform: rotate(90deg);
}

.ham8.active .bottom {
  stroke-dashoffset: -64px;
}

header {
  margin-bottom: 0.8rem;
  background-color: #FFF;
}
header .page-title {
  display: flex;
  align-items: center;
}
header .page-title h3 {
  margin: 0;
  font-size: 1.4rem;
}
header .ant-row {
  align-items: center;
}
header .header-items .header-img {
  display: flex;
  align-items: center;
}
header .header-items .header-img span {
  margin-right: 0.6rem;
}

.header-menu {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.header-menu li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  color: #000;
}
.header-menu li a span {
  font-size: 0.9rem;
  margin-top: 2px;
}

.site-header.sticky {
  background-color: #121606;
}
.site-header.sticky .navbar-brand img {
  max-width: 150px;
}
.site-header .navbar-toggler {
  color: #FFF !important;
  font-size: 46px;
  padding: 0;
  border: none;
}
.site-header .navbar-brand img {
  max-width: 120px;
}
.site-header .navbar-primary {
  padding: 0;
}
.site-header .nav-left {
  padding: 10px 0;
}
.site-header .nav-left .nav-item .nav-link {
  color: #121606;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding-right: 2.5rem;
}
.site-header .nav-left .nav-item .nav-link.active, .site-header .nav-left .nav-item .nav-link:hover, .site-header .nav-left .nav-item .nav-link:focus {
  color: #F28D20;
}
.site-header .nav-left .nav-item .button-sm {
  font-size: 0.9rem;
  font-weight: normal;
  padding: 0;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 991px) {
  .site-header {
    padding: 0 10px;
  }
  .site-header .navbar-brand img {
    max-width: 150px;
  }
}
@media screen and (max-width: 767px) {
  .site-header .navbar-nav {
    padding: 2rem 0;
  }
}
.page-head {
  background: linear-gradient(90deg, #3C40C6 0%, #FF6D4C 100%);
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}
.page-head .breadcrumb {
  justify-content: center;
}

footer {
  background-color: #F8F8F8;
  position: relative;
  padding-top: 3rem;
}
footer .footer-widget h4 {
  font-weight: 600;
  color: #121606;
  font-size: 1rem;
  margin-bottom: 1rem;
}
footer .footer-widget p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
}
footer .footer-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
footer .footer-widget ul li {
  margin-bottom: 0.5rem;
}
footer .footer-widget ul li a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}
footer .footer-widget ul li a:hover {
  color: #1677ff;
}
footer .copyright {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  margin-top: 1rem;
}
footer .copyright .row {
  align-items: center;
}
footer .copyright .footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
footer .copyright .footer-social li {
  margin-left: 1rem;
  font-size: 1.5rem;
}
footer .copyright .footer-social li a {
  color: rgba(0, 0, 0, 0.5);
}

.cta-wrap {
  position: relative;
  margin-top: 4rem;
}

.call-to-action {
  background: linear-gradient(92.22deg, #751C1C 2.32%, #A6201D 52.53%, #D7241D 98.14%);
  border-radius: 12px;
  padding: 3.75rem;
}
.call-to-action h5 {
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 300;
}
.call-to-action h1 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fbfbfb;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  font-weight: bold;
}
.call-to-action p {
  color: #FFF;
  font-size: 1.1rem;
}
.call-to-action .cta-button {
  text-align: right;
}

.footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-social li a {
  display: block;
  margin-left: 1rem;
}
.footer-social li a i {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .call-to-action {
    padding: 2.75rem;
    text-align: center;
  }
  .call-to-action .cta-button {
    text-align: center;
  }
}
.sidebar {
  width: 50%;
  background: black;
}
.sidebar .left-side ul {
  list-style-type: none;
}
.sidebar .left-side ul .react-tooltip {
  z-index: 2;
}
.sidebar .sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
.sidebar .sidebar-item:hover {
  background: #ddd;
}
.sidebar .sidebar-item i {
  margin-right: 10px;
}
.sidebar .sidebar-item .sidebar-label {
  flex-grow: 1;
}
.sidebar .sidebar-item .submenu {
  display: none;
  background: #f0f0f0;
  padding: 10px;
}
.sidebar .sidebar-item .submenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.sidebar .toggle-input {
  display: none;
}

.wrapper {
  width: 100%;
  background-color: #f1f5f9;
  height: 100vh;
  overflow: hidden;
}
.wrapper .page-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 90px);
  padding: 0 28px;
}
.wrapper .daybook-content {
  height: calc(100vh - 90px);
  padding: 0 28px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.wrapper header {
  padding: 10px 40px 10px 30px;
}
.wrapper header .header-items {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  align-items: center;
  margin: 0;
  padding: 0;
}
.wrapper header .header-items b {
  font-weight: 500;
  color: #1677ff;
}
.wrapper header .header-items span {
  color: #94a3b8;
}
.wrapper header .header-items img {
  width: 40px;
}
.wrapper .sub-menu-wrap {
  width: 190px;
  border-right: 1px solid #E2E8F0;
  background-color: #FFF;
  height: 100vh;
  padding: 20px;
}
.wrapper .sub-menu-wrap.active {
  display: none;
}
.wrapper .sub-menu-wrap h5 {
  margin: 0;
  font-size: 0.9rem;
}
.wrapper .sub-menu-wrap .sub-menu {
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0;
}
.wrapper .sub-menu-wrap .sub-menu li {
  padding-left: 15px;
  position: relative;
  margin-bottom: 0.5rem;
}
.wrapper .sub-menu-wrap .sub-menu li::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #94a3b8;
  position: absolute;
  top: 6px;
  left: 0;
}
.wrapper .sub-menu-wrap .sub-menu li a {
  font-size: 0.929rem;
  color: #94a3b8;
}
.wrapper .sub-menu-wrap .sub-menu li a:hover, .wrapper .sub-menu-wrap .sub-menu li a.active {
  color: #1677ff;
}
.wrapper .content-header {
  display: flex;
  justify-content: space-between;
  background: #fbfbfb;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.8rem 2rem;
  border-bottom-left-radius: 20px;
}
.wrapper .content-header .right {
  display: flex;
  justify-content: flex-end;
}
.wrapper .content-header .right .button {
  margin-left: 0.5rem;
}
.wrapper .content-header h1 {
  font-size: 1.5rem;
  margin: 0;
  color: #1677ff;
  font-weight: bold;
}
.wrapper > .content {
  padding: 2rem;
  position: relative;
}

.daybook-dates-wrap {
  display: flex;
  align-items: center;
}
.daybook-dates-wrap h4 {
  margin-right: 16px;
}

.page-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.page-list li a {
  display: inline-block;
  padding: 2px 4px;
  background-color: #1677ff;
  color: #FFF;
  margin-right: 5px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.3;
}
.page-list li a.current {
  background-color: #F28D20;
}

@media screen and (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .wrapper .left-side {
    transform: translateX(-100%);
    transition: all 0.5s ease;
  }
  .wrapper .left-side.active {
    transform: translateX(0);
  }
  .wrapper .right-side {
    padding-left: 0;
  }
  .wrapper .right-side > .content {
    padding: 1rem;
  }
  .wrapper .right-side .content-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }
  .wrapper .right-side .content-header h1 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
}
.auth-wrap {
  height: 100vh;
  background-color: #1677ff;
  position: relative;
  padding-top: 50px;
}
.auth-wrap .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.auth-wrap .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.auth-head {
  text-align: center;
}
.auth-head .logo {
  margin-bottom: 20px;
}
.auth-head .logo img {
  max-width: 120px;
}
.auth-head p {
  margin: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.auth-head h1 {
  margin: 0;
  color: #FFF;
  font-family: "Outfit", sans-serif;
  font-size: 1.8rem;
}

.auth-card {
  border: 1px solid #dee2e6;
  position: relative;
  padding: 2rem;
  background-color: #f4f4f4;
  width: 400px;
  margin: auto;
  border-radius: 12px;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}
.auth-card h2 {
  font-weight: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.auth-right p {
  color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 767px) {
  .auth-card {
    width: 90%;
  }
}
.welcome-wrap {
  height: 100vh;
  background-color: #1677ff;
  position: relative;
  padding-top: 20px;
}
.welcome-wrap .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}
.welcome-wrap .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.welcome-head {
  text-align: center;
}
.welcome-head .logo {
  margin-bottom: 20px;
}
.welcome-head .logo img {
  max-width: 120px;
}
.welcome-head p {
  margin: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.welcome-head h1 {
  margin: 0;
  color: #FFF;
  font-family: "Outfit", sans-serif;
  font-size: 1.8rem;
}

.welcome-card {
  border: 1px solid #dee2e6;
  position: relative;
  background-color: #f4f4f4;
  width: 1200px;
  margin: 1rem auto 0 auto;
  border-radius: 12px;
}
.welcome-card .title {
  font-weight: normal;
  color: black;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.welcome-card .welcome-content {
  padding: 1rem 3rem;
}
.welcome-card .welcome-content h1 {
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.welcome-card .welcome-content p {
  color: rgba(0, 0, 0, 0.6);
}
.welcome-card .welcome-content .ready {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}
.welcome-card .welcome-content .ready h3 {
  font-size: 1.5rem;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}
.welcome-card .welcome-content .ready h3 span {
  color: black;
}
.welcome-card .welcome-content .ready div a {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}
.welcome-card .welcome-content .ready div a i {
  margin-left: 0.5rem;
}

.auth-right p {
  color: rgba(0, 0, 0, 0.4);
}

.table-va-m tr td,
.table-va-m tr th {
  vertical-align: middle;
}

.l-info {
  margin: 8px 0 4px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.l-info li {
  margin-right: 2rem;
  display: flex;
  align-content: center;
}
.l-info li i {
  margin-right: 6px;
}
.l-info li span {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.08);
  color: #E64209;
}

.c-icon-box {
  display: flex;
}
.c-icon-box .icon {
  flex-basis: 20%;
  padding-right: 1rem;
}

.contact-box {
  display: flex;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 1.5rem;
  border-radius: 12px;
  min-height: 150px;
}
.contact-box .icon {
  padding-right: 1rem;
}
.contact-box .icon span {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1677ff;
  color: #FFF;
  font-size: 2.4rem;
  border-radius: 50%;
}

.map iframe {
  border-radius: 0.5rem;
}

.counter_bg {
  background-attachment: fixed;
  background-size: cover;
  min-height: 400px;
  display: flex;
  align-items: center;
}
.counter_bg .count-box h3 {
  color: #F28D20;
  font-weight: 600;
  font-size: 2rem;
}
.counter_bg .count-box p {
  color: rgba(255, 255, 255, 0.8);
}

.hero {
  position: relative;
  padding-top: 6rem;
  display: flex;
  align-items: center;
}
.hero .hero-content h1 {
  font-weight: bold;
  margin-bottom: 2rem;
}

.pump-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pump-logos li img {
  max-width: 170px;
  display: inline-block;
  margin: 10px;
}

.feature-section img {
  max-width: 500px;
}
.feature-section h2 {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}
.feature-section p {
  color: rgba(0, 0, 0, 0.6);
}

.reports {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.reports li {
  background-color: #FFF;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  flex-basis: 17%;
  margin: 1%;
  justify-content: center;
  box-shadow: 0px 11px 32px #0080f914;
}
.reports li img {
  width: 30px;
  opacity: 0.5;
}
.reports li i {
  font-size: 3rem;
}
.reports li p {
  font-weight: bolder;
  font-size: 1.4rem;
  max-width: 110px;
  margin: 0 auto 1.5rem auto;
  line-height: 1.8rem;
}
.reports li .button-sm {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.features_2 {
  padding: 3rem 0;
}
.features_2 .feature_box_2 {
  background-color: #FFF;
  box-shadow: 0px 11px 32px #0080f914;
  border-radius: 25px;
  padding: 2rem;
  text-align: center;
}
.features_2 .feature_box_2 .icon {
  margin-bottom: 1rem;
}
.features_2 .feature_box_2 .icon span {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
  background-color: rgba(242, 141, 32, 0.2);
  color: #F28D20;
  font-size: 2rem;
}
.features_2 .feature_box_2 h3 {
  font-size: 1.2rem;
}
.features_2 .feature_box_2 p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.cta {
  background-color: #1677ff;
  padding: 5rem 0;
}
.cta .cta-content {
  text-align: center;
  color: #FFF;
}
.cta .cta-content h3 {
  font-size: 2.5rem;
  margin-bottom: 1.4rem;
}
.cta .cta-content p {
  font-size: 1.2rem;
  font-weight: 300;
}
.cta .cta-content .cta-buttons {
  margin-top: 2rem;
}
.cta .cta-content .cta-buttons .button {
  margin: 0.5rem;
}

.pump-reading-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.pump-reading-item:hover {
  background-color: #fbfbfb;
}
.pump-reading-item:hover .pump-name .number {
  background-color: #1677ff;
  color: #FFF;
}
.pump-reading-item .pump-name {
  display: flex;
  align-items: center;
  flex-basis: 15%;
}
.pump-reading-item .pump-name .number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  background-color: rgba(6, 25, 86, 0.2);
  color: #1677ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.6rem;
  font-weight: bold;
  flex-shrink: 0;
}
.pump-reading-item .pump-name.petrol .number {
  background-color: #549B8C;
  color: #FFF;
}
.pump-reading-item .pump-name.diesel .number {
  background-color: #BD9B16;
  color: #FFF;
}
.pump-reading-item .readings {
  margin: 0 1rem;
  display: flex;
  flex-basis: 65%;
}
.pump-reading-item .readings > div {
  width: 33%;
}
.pump-reading-item .readings .total p {
  margin: 0;
}
.pump-reading-item .readings .total h4 {
  margin: 0;
}
.pump-reading-item .readings label {
  font-size: 0.8rem;
  padding-right: 0;
  white-space: nowrap;
}
.pump-reading-item .readings .last-reading .form-floating .form-control {
  margin-left: -1px;
  border-radius: 12px 0 0 12px;
}
.pump-reading-item .readings .last-reading .form-floating .form-control:focus {
  position: relative;
  z-index: 1;
}
.pump-reading-item .readings .last-reading .form-floating .form-control:focus ~ label {
  z-index: 1;
}
.pump-reading-item .readings .current-reading {
  margin-left: -2px;
}
.pump-reading-item .readings .current-reading .form-floating .form-control {
  border-radius: 0;
}
.pump-reading-item .readings .returned {
  margin-left: -1px;
}
.pump-reading-item .readings .returned .form-floating .form-control {
  border-radius: 0 12px 12px 0;
}
.pump-reading-item .qty {
  flex-basis: 20%;
}
.pump-reading-item .qty p {
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}
.pump-reading-item .qty h4 {
  margin: 0;
  font-size: 1.3rem;
}

.card-sales-list {
  min-height: 481px;
}

.table-sales-list {
  font-size: 0.9rem;
}

.products-total {
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.products-total .pt-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  flex: 1;
  background-color: #FFF;
  border-radius: 12px;
}
.products-total .pt-row .value {
  font-weight: bold;
}
.products-total .pt-row:last-child {
  border-bottom: none;
}

.pump-icon {
  position: relative;
}
.pump-icon p {
  margin: 0;
  position: absolute;
}
.pump-icon img {
  max-width: 60px;
}

.sale-wrap {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
}
.sale-wrap .sale-head {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sale-wrap .sale-head h4 {
  font-size: 16px;
  margin: 0;
}
.sale-wrap .sale-head h4 span {
  font-size: 16px;
  display: inline-block;
  margin-right: 6px;
  color: rgba(0, 0, 0, 0.5);
}
.sale-wrap .sale-footer {
  padding: 20px 40px;
  border-top: 1px solid #e2e8f0;
}
.sale-wrap .sale-item {
  padding: 20px 40px;
  border-top: 1px solid #e2e8f0;
}
.sale-wrap .sale-item.bg-light1 {
  background-color: #F8FAFC;
}
.sale-wrap .sale-item h5 {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0;
}
.sale-wrap .sale-item h5 span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
  .pump-reading-item {
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .pump-reading-item .pump-name {
    margin-bottom: 1rem;
  }
  .pump-reading-item .readings {
    margin: 0;
  }
}
.v-account-info {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.42rem 0.75rem;
  display: flex;
  justify-content: space-between;
}
.v-account-info .item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.9rem;
}
.v-account-info .item .label {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
}
.v-account-info .item .value {
  font-weight: 600;
}

.dashboard-content .card {
  position: relative;
}
.dashboard-content .card .card-info {
  display: flex;
  justify-content: space-between;
}
.dashboard-content .card .card-body .icon-2 {
  color: #eab308;
}
.dashboard-content .card .card-body .icon-3 {
  color: #8b5cf6;
  top: 17px;
}
.dashboard-content .card .card-body .icon-4 {
  color: #ff7761;
  top: 16px;
}
.dashboard-content .card .card-body .icon-6 {
  color: #061956;
  top: 15px;
}
.dashboard-content .card .card-body .arrow-color {
  color: red;
}
.dashboard-content .card .card-body span {
  position: absolute;
  top: 81px;
  position: absolute;
  left: 32px;
  color: #14b8a6;
}
.dashboard-content .card .card-body b {
  color: #14b8a6;
  margin-left: 15px;
}
.dashboard-content .card .card-body .circle {
  width: 40px;
  height: 40px;
  background-color: #ccfbf1;
  border-radius: 50%;
  position: absolute;
  left: 178px;
  top: 9px;
}
.dashboard-content .card .card-body .circle-2 {
  width: 40px;
  height: 40px;
  background-color: #fef9c3;
  border-radius: 50%;
  position: absolute;
  left: 178px;
  top: 9px;
}
.dashboard-content .card .card-body .circle-3 {
  width: 40px;
  height: 40px;
  background-color: #e5e7eb;
  border-radius: 50%;
  position: absolute;
  left: 178px;
  top: 9px;
}
.dashboard-content .card .card-body .circle-4 {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 119, 97, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 178px;
  top: 9px;
}
.dashboard-content .card .card-body .circle-6 {
  width: 40px;
  height: 40px;
  background-color: rgba(6, 25, 86, 0.2);
  border-radius: 50%;
  position: absolute;
  left: 178px;
  top: 9px;
}
.dashboard-content .right-one .card {
  height: 259px;
  position: relative;
}
.dashboard-content .right-one .card .card-body {
  padding: 18px !important;
  margin-top: 10px;
}
.dashboard-content .right-one .card .card-body .phone-no p {
  margin-left: 25px;
  color: #756e6e;
  font-weight: 700;
}
.dashboard-content .right-one .card .card-body .phone-no span {
  left: 18px;
  top: 107px;
  color: #8b5cf6;
}
.dashboard-content .right-one .card .card-body .email p {
  margin-left: 30px;
  color: #756e6e;
  font-weight: 700;
}
.dashboard-content .right-one .card .card-body .email span {
  top: 144px;
  left: 19px;
  color: #8b5cf6;
}
.dashboard-content .right-one .card .card-body .location p {
  margin-left: 30px;
  color: #756e6e;
  font-weight: 700;
}
.dashboard-content .right-one .card .card-body .location span {
  top: 179px;
  left: 19px;
  color: #8b5cf6;
}
.dashboard-content .right-one .card .card-body p {
  margin-top: -3px;
  font-size: 12px;
  color: black;
}
.dashboard-content .right-one .card .card-body img {
  width: 40px;
  display: block;
  position: absolute;
  top: 31px;
  left: 175px;
}
.dashboard-content .stocks .card {
  position: relative;
}
.dashboard-content .stocks .card span {
  position: absolute;
  left: 100px;
  top: 10px;
  color: orange;
}
.dashboard-content .stocks .card .stocks-info {
  background-color: #f3f5f9;
  text-align: center;
  padding: 8px 12px;
  border-radius: 12px;
}
.dashboard-content .stocks .card .stocks-info b {
  color: #756e6e;
  font-weight: 400;
}
.monthly-info {
  text-align: center;
}

.report-header {
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 20px;
}
.report-header table tr td {
  border-collapse: collapse;
  padding: 3pt 0;
}
.report-header table tr td .logo {
  display: flex;
  align-items: center;
}
.report-header table tr td .logo img {
  width: 50px;
  flex-shrink: 0;
  height: auto;
}
.report-header table tr td .logo .company {
  padding-left: 14px;
}
.report-header table tr td .logo .company h3 {
  font-family: "Outfit", sans-serif;
  margin: 0;
  font-size: 18pt;
  font-weight: bold;
}
.report-header table tr td .logo .company p {
  margin: 0;
  font-size: 9pt;
}
.report-header table tr td .header-right {
  text-align: right;
}
.report-header table tr td .header-right tr td {
  font-size: 10pt;
}
.report-header table tr td .header-right tr td:last-child {
  width: 30px;
}
.report-header table tr td .header-right tr td i {
  font-size: 12pt;
}

.table-summary .col1 {
  width: 60%;
  vertical-align: top;
}
.table-summary .col2 {
  padding-left: 10pt;
  width: 40%;
  vertical-align: top;
}
.table-summary .report-table tr:last-child td {
  border-bottom: none;
}
.table-summary .report-table td {
  padding: 3pt 6pt;
  font-size: 9pt;
}

.report-stock-wrap {
  display: flex;
  flex-wrap: wrap;
}
.report-stock-wrap .report-stock-item {
  padding: 6px 0;
  width: 50%;
  border-bottom: 1px solid;
  font-size: 10pt;
}
.report-stock-wrap .report-stock-item h2 {
  font-size: 10pt;
  font-weight: bold;
  margin: 0 0 2pt 0;
  text-transform: uppercase;
}
.report-stock-wrap .report-stock-item:nth-child(even) {
  border-left: 1px solid;
  padding-left: 20px;
}
.report-stock-wrap .report-stock-item:last-child {
  border-bottom: none;
}
.report-stock-wrap .report-stock-item:nth-last-child(2) {
  border-bottom: none;
}

.report-date-wise-summary th, .report-date-wise-summary td {
  font-size: 8pt !important;
  padding: 5pt !important;
}

.print-body {
  background-color: #212121;
  font-size: 12pt;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 0 0.5cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.report-title {
  font-family: "Outfit", sans-serif;
  font-size: 14pt;
  font-weight: bold;
}
.report-title.bbottom {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.report-section-title {
  font-family: "Outfit", sans-serif;
  font-size: 12pt;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.subpage {
  padding: 0.5cm 0.5cm 0 0.5cm;
  min-height: 256mm;
}
.subpage > h1 {
  font-family: "Outfit", sans-serif;
  font-size: 15pt;
  font-weight: bold;
}
.subpage table {
  width: 100%;
  border-collapse: collapse;
}

@page {
  size: A4;
  margin: 0;
}
.report-table thead {
  background-color: rgba(0, 0, 0, 0.2);
}
.report-table tfoot {
  background-color: rgba(0, 0, 0, 0.1);
}
.report-table tfoot td {
  border-bottom: none;
}
.report-table th {
  font-size: 10pt;
  padding: 5pt 10pt;
  text-align: left;
}
.report-table td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 10pt;
  padding: 5pt 10pt;
}
.report-table .current-balance {
  width: 80px;
  text-align: right;
}
.report-table .actype {
  width: 135px;
}

.report-header {
  display: flex;
  justify-content: space-between;
}
.report-header .text-right {
  text-align: right;
}

@media print {
  body {
    background-color: #FFF;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .report-table thead {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-print-color-adjust: exact !important;
  }
  .report-table tfoot {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-print-color-adjust: exact !important;
  }
}

