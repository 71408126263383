.user {
    display: flex;
    align-items: center;
    padding: .6rem 1rem;
    background-color: rgb(241, 245, 249);
    .photo {
        img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            border: 8px solid rgba(255, 255, 255, 0.2);
            box-shadow: 0 1rem 2rem rgba(0, 0, 0, .1);
        }
    }

    .info {
        padding-left: .5rem;

        h3 {
            margin: .5rem 0 0 0;
            text-transform: capitalize;
            font-size: 1rem;

            .badge {
                padding: 3px 10px;
                font-size: 1rem;
            }
        }

        p {
            margin: 0;
            font-size: .7rem;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    display: flex;
                    align-items: center;
                    @include textBlack(0.5);
                    font-size: .8rem;

                    i {
                        margin-right: 4px;
                        margin-top: 1px;
                        font-size: 1rem;
                    }

                    &.verified i {

                        color: $green;
                    }

                    &.not-verified i {

                        color: $red;
                    }
                }
            }
        }
    }


}