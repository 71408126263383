.widget {
    background-color: #FFF;
    border: 1px solid #E0DFDC;
    border-radius: $radius;
    padding: 20px;
    margin-bottom: 20px;

    .widget-title {
        font-size: 16px;
        font-weight: bold;
        color: $themeBlack;
        position: relative;

        .action {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .widget-body {
        padding-top: 20px;
    }
}


    .list-info-body {
        display: flex;
        justify-content: space-between;
        margin-bottom: .8rem;
        .list-info-value {
            font-weight: 700;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .info-box {
            display: flex;
            align-items: center;

            .list-acc-info {
                padding: 0px 10px;
                line-height: 1.2rem;
                p {
                    font-weight: 500;
                    margin: 0;
                }

                span {
                    font-size: 0.90rem;
                    color: rgb(148, 163, 184)
                }

            }

            .circle {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                color: white;
                display: block;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $primary;

                span,i {
                    font-size: 1.3rem;
                }

            }
        }
    }

    .user-box {

        .box-info {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-right: 0.4px solid rgb(207, 201, 201);

            &.border-none {
                border-right: none;
            }

            i {
                font-size: 2.2rem;
            }

            b {
                font-weight: 600;
                font-size: 1.25rem;
            }

        }
    }
