//colors
$primary: #1677ff;
$secondary: #F28D20;
$green: #00B5BD;
$red: #FF7761;
$themeBlack: #121606;
$themeLight: #fbfbfb;
$themeDark: #191824;
$lightGray: #F8F8F8;
$warning: #F4B71E;
$gray: #7E7E7E;


$color-petrol: #549B8C;
$color-diesel: #BD9B16;

// fonts

$font-primary: 'Outfit', sans-serif;
$font-secondary: 'Outfit', sans-serif;


// general

$radius: 12px;
