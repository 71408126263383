.auth-wrap {
	height: 100vh;
	background-color: $primary;
	position: relative;
	padding-top: 50px;
	.shape-1 {
		position: absolute;
		top: 0;
		right: 0;
	}
	.shape-2 {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.auth-head {
	text-align: center;
	.logo {
		img {
			max-width: 120px;
		}

		margin-bottom: 20px;
	}

	p {
		margin: 0;
		font-weight: 300;
		color: rgba(255, 255, 255, .7);
	}
	h1 {
		margin: 0;
		color: #FFF;
		font-family: $font-secondary;
		font-size: 1.8rem;
	}
}

.auth-card {
	border: 1px solid #dee2e6;
	position: relative;
	padding: 2rem;
	background-color: #f4f4f4;
	width: 400px;
	margin: auto;
	border-radius: $radius;
	margin-top: 2rem;
	position: relative;
	z-index: 1;
	h2 {
		font-weight: normal;
		text-align: center;
		@include textBlack(0.5);
		margin-bottom: 1rem;
		font-size: 1.4rem;
	}
}

.auth-right {
	p {
		@include textBlack(0.4);
	}
}

@media screen and (max-width: 767px) {
	.auth-card {
		width: 90%;
	}
}