@mixin textStroke($borderColor) {
	-webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $borderColor;
    -webkit-text-fill-color: rgb(0 0 0/0%);
}

@mixin textStrokeNone($fillColor) {
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: translate;
    -webkit-text-fill-color: $fillColor;
}


@mixin textBlack($opacity) {
    color: rgba(0, 0, 0, $opacity);
}

@mixin gradient() {
    background: linear-gradient(90deg, #3C40C6 0%, #FF6D4C 100%);
}


@mixin bgPrimary($opacity) {
    background-color: rgba(6, 25, 86, $opacity);
}


@mixin bgSecondary($opacity) {
    background-color: rgba(242, 141, 32, $opacity);
}

@mixin bgRed($opacity) {
    background-color: rgba(255, 119, 97, $opacity);
}



@mixin bgGreen($opacity) {
    background-color: rgba(0, 181, 189, $opacity);
}


@mixin bgWarning($opacity) {
    background-color: rgba(244, 183, 30, $opacity);
}
