.dashboard-content {
	.card {
		.card-info {
			display: flex;
			justify-content: space-between;
		}
		position: relative;

		

		.card-body {

			.icon-2 {
				color: rgb(234, 179, 8);
			}

			.icon-3 {
				color: rgb(139, 92, 246);
				top: 17px;
			}

			.icon-4 {
				color: rgb(255, 119, 97);
				top: 16px;
			}

			.icon-6 {
				color: rgb(6, 25, 86);
				top: 15px;
			}

			.arrow-color {
				color: red;
			}

			span {
				position: absolute;
				top: 81px;
				position: absolute;
				left: 32px;
				color: rgb(20, 184, 166);


			}

			b {
				color: rgb(20, 184, 166);
				margin-left: 15px;
			}

			.circle {
				width: 40px;
				height: 40px;
				background-color: rgb(204, 251, 241);
				border-radius: 50%;
				position: absolute;
				left: 178px;
				top: 9px;
			}

			.circle-2 {
				width: 40px;
				height: 40px;
				background-color: rgb(254, 249, 195);
				border-radius: 50%;
				position: absolute;
				left: 178px;
				top: 9px;
			}

			.circle-3 {
				width: 40px;
				height: 40px;
				background-color: rgb(229, 231, 235);
				border-radius: 50%;
				position: absolute;
				left: 178px;
				top: 9px;
			}

			.circle-4 {
				width: 40px;
				height: 40px;
				background-color: rgba(255, 119, 97, 0.2);
				border-radius: 50%;
				position: absolute;
				left: 178px;
				top: 9px;
			}

			.circle-6 {
				width: 40px;
				height: 40px;
				background-color: rgba(6, 25, 86, 0.2);
				border-radius: 50%;
				position: absolute;
				left: 178px;
				top: 9px;
			}
		}
	}

	.right-one {
		.card {
			height: 259px;
			position: relative;

			.card-body {
				padding: 18px !important;
				margin-top: 10px;

				.phone-no {
					p {
						margin-left: 25px;
						color: rgb(117, 110, 110);
						font-weight: 700;
					}

					span {
						left: 18px;
						top: 107px;
						color: rgb(139, 92, 246);

					}

				}

				.email {
					p {
						margin-left: 30px;
						color: rgb(117, 110, 110);
						font-weight: 700;
					}

					span {
						top: 144px;
						left: 19px;
						color: rgb(139, 92, 246);
					}
				}

				.location {
					p {
						margin-left: 30px;
						color: rgb(117, 110, 110);
						font-weight: 700;
					}

					span {
						top: 179px;
						left: 19px;
						color: rgb(139, 92, 246);
					}
				}

				p {
					margin-top: -3px;
					font-size: 12px;
					color: black;
				}

				img {

					width: 40px;
					display: block;
					position: absolute;
					top: 31px;
					left: 175px;

				}
			}
		}
	}

	.stocks {
		.card {
			
			position: relative;
			

			span {
				position: absolute;
				left: 100px;
				top: 10px;
				color: orange;
			}

			.stocks-info {
				background-color: rgb(243, 245, 249);
				text-align: center;
				padding: 8px 12px;
				border-radius: 12px;

				b {
					color: rgb(117, 110, 110);
					font-weight: 400;
				}

				h3 {
					
				}

			}

		}
	}

	
}
.monthly-info{
	text-align: center;
}