.site-header {
    
    // background-color: transparent;
    // position: fixed;
    // top: 0;
    // width: 100%;
    // z-index: 3;
    &.sticky {
       
        
        background-color: $themeBlack;

        .navbar-brand {
            img {
                max-width: 150px;
            }
        }
    }


    .navbar-toggler {
        color: #FFF !important;
        font-size: 46px;
        padding: 0;
        border: none;
    }

    .navbar-brand {
        img {
            max-width: 120px;
        }
    }


    .navbar-primary {
        padding: 0;
    }

  

    .nav-left {
        padding: 10px 0;
        .nav-item {
            .nav-link {
                color: $themeBlack;
                text-transform: uppercase;
                font-size: .9rem;
                padding-right: 2.5rem;
                &.active,
                &:hover,
                &:focus {
                    color: $secondary;
                }
            }

            .button-sm {
                font-size: .9rem;
                font-weight: normal;
                padding: 0;
                padding: .5rem 1rem;
            }
        }
    }
}



@media screen and (max-width: 991px) {
    .site-header {
        padding: 0 10px;

        .navbar-brand {
            img {
                max-width: 150px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .site-header {
        .navbar-nav {
            padding: 2rem 0;
        }
    }
}