.input-icon {
  position: relative;

  .form-control,
  .form-select {
    
  
    padding-left: 3rem !important;
   
  }

  span {
    
    position: absolute;
    left: 10px;
    top: 38px;
    border-radius: 50%;
    color: rgba(59,130,246,.5);
    font-size: 1.6rem;
  }
}

.form-control,
.form-select {
  &:focus {
      outline: 1px solid $primary;
      box-shadow: none;
  }

  &:focus ~ span {
    color: $primary;
  }
}


.form-group {
  label {
    margin-bottom: 0.3rem;
    @include textBlack(0.6);

  }
  .form-control, .form-select {
    border-radius: $radius;
    min-height: 48px;

    &:placeholder {
      @include textBlack(0.4);
    }
  }
}

.input-vertical > .ant-row {
  display: block;
}

.radio-logos {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
}
.radio-logos li  {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2rem 0 0 0;
    border-radius: $radius;
    position: relative;
    background-color: #FFF;
    flex-basis: 14%;
    margin: 1%;
    opacity: none;
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }

    input {
        opacity: 0;
        position: absolute;
        left: 0;

        &+label {
            display: block;
            cursor: pointer;
            text-align: center;

            &::before {
                font-family: remixicon !important;
                font-weight: 400;
                content: "\EB7D";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                margin: auto;
                font-size: 1.3rem;
            }

            img {
              max-width: 40px;
              margin-bottom: .5rem;
            }
        }



        &:checked+label {
            &::before {
                content: "\EB80";
                color: $primary;
            }

            .count {
                background-color: $secondary;
                color: #FFF;
            }
        }
    }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}