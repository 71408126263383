.print-body {
    background-color: #212121;
    font-size: 12pt;
}


.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 0 .5cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);


}

.report-title {
    font-family: $font-primary;
    font-size: 14pt;
    font-weight: bold;

    &.bbottom {
        border-bottom: 1px solid;
        padding-bottom: 10px;
    }
}



.report-section-title {
    font-family: $font-primary;
    font-size: 12pt;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.subpage {
    padding: .5cm .5cm 0 .5cm;
    min-height: 256mm;

    >h1 {
        font-family: $font-primary;
        font-size: 15pt;
        font-weight: bold;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }
}

@page {
    size: A4;
    margin: 0;
}

.report-table {
    thead {
        background-color: rgba(0, 0, 0, 0.2);
    }

    tfoot {
        background-color: rgba(0, 0, 0, 0.1);
        td {
                border-bottom: none;
            }
    }

    th {
        font-size: 10pt;
        padding: 5pt 10pt;
        text-align: left;
    }

    td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        font-size: 10pt;
        padding: 5pt 10pt;

    }

    .current-balance {
        width: 80px;
        text-align: right;
    }

    .actype {
        width: 135px;
    }
}

.report-header {
    display: flex;
    justify-content: space-between;
    .text-right {
        text-align: right;
    }
}

@media print {
    body {
        background-color: #FFF;
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }

    .report-table {
        thead {
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-print-color-adjust: exact !important;
        }

        tfoot {
            background-color: rgba(0, 0, 0, 0.2);
            -webkit-print-color-adjust: exact !important;
        }


    }
}